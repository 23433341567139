/** React / Utils */
import React, { useState, useContext, useEffect } from 'react';

/** Material UI */
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import AddBankPaymentForm from './addBankPaymentForm';
import AddCCPaymentForm from './addCCPaymentForm';
import AddGCPaymentForm from './addGCPaymentForm';
import Geo from '../../../../../constants/geos.const';
import { OrderContext } from '../../../../../store/contexts/orderContext';
import { DialogContext } from '../../../../../store/contexts/dialogContext';
import DialogActions from '../../../../../store/actions/dialogActions';
import translations from '../payments.i18n';
import useMemoTranslations from '../../../../../hooks/useMemoTranslations';
import SimpleModalDialog from '../../../../shared/simpleDialog';

/**
 * Container component that will encompass all the different forms used to to an existing order
 * a new form of payment. It will chose the form to render based on the radio buttons listed.
 *
 * @param {boolean} isDialogOpen - variable to indicate if the Add Payment dialog open
 * @param {function} setDialogOpen - function set the Add Payment dialog open or closed
 * @param {function} updatePaymentMethods - function to query payment details after payment is added
 * @param {object} activeCC - credit card details used to suspend active credit card if it exists
 * @returns a modal dialog that containing add payment method form
 */
export default function AddPaymentMethodDialog({
  isDialogOpen,
  setDialogOpen,
  updatePaymentMethods,
  activeCC,
}) {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [, dialogDispatch] = useContext(DialogContext);
  const { reset } = DialogActions;
  const { omsRegionReference: region } = orderDetail;
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [methodType, setMethodType] = useState('creditCard');
  const [triggerSubmission, setTriggerSubmission] = useState(false);

  const {
    ADD_NEW_PAYMENT_METHOD,
    CANCEL,
    CREDIT_CARD_PURPOSE_WARNING,
    APPLY,
  } = useMemoTranslations(translations);

  useEffect(() => {
    // reset on close
    if (!isDialogOpen) {
      setSubmitDisabled(true);
      setMethodType('creditCard');
      setTriggerSubmission(false);
      dialogDispatch(reset());
    }
  }, [isDialogOpen]);

  const handleMethodChange = (e) => {
    setMethodType(e.target.value);
    dialogDispatch(reset());
  };

  const renderPaymentMethodForm = () => {
    switch (methodType) {
      case 'creditCard':
        return (
          <AddCCPaymentForm
            shouldSubmitBeDisabled={setSubmitDisabled}
            triggerSubmission={triggerSubmission}
            setTriggerSubmission={setTriggerSubmission}
            updatePaymentMethods={updatePaymentMethods}
            activeCC={activeCC}
            setDialogOpen={setDialogOpen}
          />
        );
      case 'giftCardVoucher':
        return (
          <AddGCPaymentForm
            shouldSubmitBeDisabled={setSubmitDisabled}
            triggerSubmission={triggerSubmission}
            setTriggerSubmission={setTriggerSubmission}
            updatePaymentMethods={updatePaymentMethods}
            setDialogOpen={setDialogOpen}
          />
        );
      case 'ofbt':
        return (
          <AddBankPaymentForm
            shouldSubmitBeDisabled={setSubmitDisabled}
            triggerSubmission={triggerSubmission}
            setTriggerSubmission={setTriggerSubmission}
            updatePaymentMethods={updatePaymentMethods}
            setDialogOpen={setDialogOpen}
          />
        );
      default:
        return null;
    }
  };

  return (
    <SimpleModalDialog
      isOpen={isDialogOpen}
      setIsOpen={setDialogOpen}
      title={ADD_NEW_PAYMENT_METHOD}
      cancelLabel={CANCEL}
      testId={'add-payment-modal'}
      bottomText={
        methodType === 'creditCard' && region === Geo.US ? CREDIT_CARD_PURPOSE_WARNING : ''
      }
      manualClose={true} // so modal won't close if add payment fails
      content={
        <>
          <FormControl>
            <RadioGroup
              row
              defaultValue={methodType}
              aria-labelledby='add-payment-row-radio-buttons-group'
              name='add-payment-row-radio-buttons-group'
              data-testid={'add-payment-radio-buttons'}>
              <FormControlLabel
                value='creditCard'
                control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                label='Credit Card'
                data-testid='add-payment-credit-card-radio'
                onChange={handleMethodChange}
                className={classes.label}
              />
              {region !== Geo.AUSTRALIA && (
                <FormControlLabel
                  value='giftCardVoucher'
                  control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                  label='Existing Gift Card/Voucher'
                  data-testid='add-payment-gift-card-radio'
                  onChange={handleMethodChange}
                  className={classes.label}
                />
              )}
              {region === Geo.EUROPE && (
                <FormControlLabel
                  value='ofbt'
                  control={<Radio classes={{ root: classes.radio, checked: classes.checked }} />}
                  label='OFBT'
                  data-testid='add-payment-ofbt-radio'
                  onChange={handleMethodChange}
                />
              )}
            </RadioGroup>
            <span className={classes.topSpacer} />
          </FormControl>
          {methodType && renderPaymentMethodForm()}
        </>
      }
      confirmOptions={{
        label: APPLY,
        action: () => setTriggerSubmission(true),
        disabled: submitDisabled,
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  radio: {
    '&$checked': {
      color: theme.palette.common.black,
    },
  },
  checked: {},
  topSpacer: {
    marginTop: '1rem',
  },
  label: {
    marginRight: '3rem',
  },
}));
